/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  // "BoonHome", 
    font-family: "Noto Sans Lao", "saysettha OT" !important;
  }
  .header {
    background: #30b1f2;
    --background: #30b1f2;
  }
  
  .static {
    position: static !important;
  }
  
  .osahan-nav ion-toolbar {
    --background: #770E12 !important;
    --color: #fff;
    --background-color: #770E12 !important;
    --ion-color-base: transparent !important;
  }
  .back-icon-color{
    color: #ffffff;
  }
  .back-icon-background-color{
    // background-color: #ffffff;
  }
  ion-item.selected ion-ripple-effect {
    --background: rgba(233, 97, 37, 0.09) !important;
  }
  .osahan-nav {
    background: #770E12 !important;
  }
  .osahan-nav ion-title {
    font-size: 16px;
    line-height: 18px;
    padding: 0 6px;
  }
  .osahan-nav ion-title small {
    font-size: 12px;
  }
  
  .top-cart ion-badge {
    position: absolute;
    padding: 2px;
    font-size: 11px;
    top: 0;
    right: 0;
    min-width: 15px;
    height: 15px;
    border-radius: 50px;
    text-align: center;
    background-color: red;
  }
  .top-cart ion-icon {
    font-size: 27px !important;
    color: #fff;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  .mr-5px{
    margin-right: 5px !important;
  }
  p.small {
    font-size: 15px;
  }
  .text-danger {
    color: red;
  }
  .item-interactive.ion-valid {
    // --highlight-background: var(--highlight-color-valid);
    --highlight-background: #3880FF !important;
  }
  .text-center {
    text-align: center !important;
  }
  .title{
    margin-left: 10px;
    font-weight: bold;
  }
  .b-bottom{
    border-color: rgb(217, 217, 217); 
    border-width: 0px 0px 1px 0px; 
    border-style:solid; 
  }
  .item-tran{
    --background: transparent !important;
  }
  ion-segment {
    --offset-bottom: auto!important;
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .margin-left{
    margin-left: 5px;
  }

  .border{
    border-radius: 10px;
    border: 1px solid #DEDEDE;
    margin-bottom: 15px;
   border-bottom: 2px solid var(--ion-color-primary);
  
  }
  
  
  //=========================================================== for home category product-list ===============================================================
  .img{
    position: relative;
  }
  .heart{
    background-color: #ffffff;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 100%;
  font-size: 20px;
  color: red;
  }
  .heart1{
    background-color: green;
  position: absolute;
  top: 5px;
  left: 5px;
  color: #ffffff;
  padding: 0px 5px;
  }
  .truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  .pic-center{
  margin:10px auto;
  width: 80px;
  height: 80px;  
  }

  @media screen and (min-width:992px){
    .hide{
        display: none;
    }
}

.dialog-modal{
  // --width: 100%;
  //   --height: 100%;
}

.item-interactive.ion-valid {
  // --highlight-background: #3880FF !important;
  --highlight-background: #BA4751 !important;
}
.app-alert .alert-wrapper {
  .alert-head {
    background: var(--ion-color-primary);
    color: white;
    margin-bottom: 15px;

    h2 {
      color:
        white;
    }
  }

  .alert-button-group {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      border: 1px solid var(--ion-color-primary);
      width: 45%;
      border-radius: 6px;
      margin-left: 3px;
      &:last-child {
        background: var(--ion-color-primary);
        color: white;
        border: 1px solid var(--ion-color-primary)
      }

      span {
        display: flex;
        justify-content: center;
      }
    }
  }
}
